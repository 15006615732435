<template>
    <headers></headers>
    <div class="top-gap"></div>
    <div class="stack">
        <div>
            <div class="title">
                <b>Stack <span>USDT</span> faster</b>
            </div>
            <p>{{ $t('lp.stackDesc') }}</p>
          <div class="amount">
            <div>
              <div>{{ $t('lp.stackList1') }}</div>
              <div class="num">{{Util.showAmount(stake.staking)}} USDT</div>
            </div>
            <div>
              <div>{{ $t('lp.stackList2') }}</div>
              <div class="num">{{Util.showAmount(stake.interest)}} USDT</div>
            </div>
            <div>
              <div>{{ $t('lp.stackList3') }}</div>
              <div class="num">{{Util.showAmount(stake.compound)}} ATI</div>
            </div>
          </div>
        </div>
    </div>
    <!-- 质押记录入口 -->
    <div class="record-but">
        <button>{{ $t('lp.record') }}</button>
    </div>
    <div class="record">
        <div>
            <div>
                <div @click="getType(1)" :class="typeIndex==1?'get-type':''">ATI AI 7</div>
                <div @click="getType(2)" :class="typeIndex==2?'get-type':''">ATI AI 14</div>
                <div @click="getType(3)" :class="typeIndex==3?'get-type':''">ATI AI 28</div>
            </div>
            <button @click="goPage('lp')">
                {{ $t('lp.back') }}
                <img src="../../static/images/arrow.png" >
            </button>
        </div>
    </div>
    <!-- 质押记录列表 -->
    <div class="list">
        <div>
            <div v-for="(item,index) in stakes.list" :key="index">
                <div>
                    <div class="id">No. {{ item.id }}</div>
                    <b>{{ $t('lp.money') }}: {{ Util.showAmount(item.amount) }} USDT</b>
                    <div class="time">{{ $t('lp.timeStart') }}: {{ item.orderTime }}</div>
                    <div class="time">{{ $t('lp.timeEnd') }}: {{ item.endTime }}</div>
                </div>
                <!-- 取消自动续期 -->

                  <div class="state" v-if="item.btnState === -1">已赎回</div>
                  <div class="state" v-else-if="item.btnState === 0">赎回中...</div>
                  <div class="state" v-else-if="item.btnState === 1">质押中...</div>
                  <button class="cancel" @click="cancelAuto(item.serial)" v-else-if="item.btnState === 2">{{ $t('lp.cancel') }}</button>
                  <button class="disabled" v-else-if="item.btnState === 3" >{{ $t('lp.redeem') }}</button>
                  <button v-else @click="redeem(item.serial)">{{ $t('lp.redeem') }}</button>

               <!-- 赎回 -->
            </div>
        </div>
        <div class="more" v-if="stakes.page - stakes.totalPage < 0" @click="getNextStakeList">{{ $t('lp.more') }}</div>
        <no-data v-if="!stakes.totalRecord"></no-data>
    </div>
    <bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import {ref, reactive, onMounted, computed, watch} from 'vue';
    import {useRoute, useRouter} from 'vue-router';
    import {call, send} from "@/contract/web3-util";
    import Util from "@/utils/common-util";
    import Web3 from "web3";
    import {queryInfo, queryStakeList, cancelAutoRenewal, redeemOrder} from "@/api/lp";

    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {end, start} from "@/api/loading";

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = useRouter();
    const route = useRoute()

    const typeIndex = ref(1)

    const stake = reactive({
      staking: 0,
      interest: 0,
      compound: 0
    })

    const stakes = reactive({
      page: 1,
      pageSize: 0,
      totalPage: 0,
      totalRecord: 0,
      list: [],
    })

    function getStakeList() {
      if (!wbStore.isLogin()) {
        return
      }

      stakes.list = []
      stakes.page = 1
      stakes.pageSize = 0
      stakes.totalPage = 0
      stakes.totalRecord = 0
      getStakes()
    }
    function getNextStakeList() {
      if (!wbStore.isLogin()) {
        return
      }

      if (stakes.page < stakes.totalPage) {
        stakes.page++
      } else {
        return
      }

      getStakes()
    }

    function getStakes() {
      if (!wbStore.isLogin()) {
        return
      }

      queryStakeList({page: stakes.page, pageSize: 6, type: typeIndex.value}).then(function (result) {
        if (Util.isDefine(result) && result.data.code === 1) {
          const data = result.data.data.pagingList
          stakes.page = data.currPage
          stakes.pageSize = data.pageSize
          stakes.totalPage = data.totalPage
          stakes.totalRecord = data.totalRecord

          const list = data.resultList
          for (let i = 0; Util.isDefine(list) && i < list.length; i++) {
            const a = list[i]
            const stake = {}
            stake.token = 'USDT'
            stake.amount = a.amount - 0
            stake.id = a.id
            stake.serial = a.serial
            stake.state = a.state
            stake.autoRenewal = a.autoRenewal
            stake.time = a.time
            stake.renewalTime = a.renewalTime
            stake.type = a.type

            stakes.list.push(countStake(stake))
          }
        }
      })
    }

    function countStake(stake) {
      const orderTime = Util.timestampToDate(stake.time)
      stake.orderTime = orderTime.year + '-' + orderTime.month + '-' + orderTime.day + ' ' + orderTime.time

      let intervalDays = stake.type === 1 ? 7 : (stake.type === 2 ? 14 : 28)
      stake.end = stake.renewalTime + intervalDays * 24 * 3600
      const endTime = Util.timestampToDate(stake.end)
      stake.endTime = endTime.year + '-' + endTime.month + '-' + endTime.day + ' ' + endTime.time

      if (stake.state === 1) {
        stake.btnState = -1 // 已赎回
      } else if (stake.state === -1) {
        stake.btnState = 0 // 赎回中
      } else {
        const nowTime = Math.floor(new Date() / 1000)
        // 离到期时间超过3天
        if (stake.end - nowTime > 3 * 24 * 3600) {
          stake.btnState = 1 // 质押中
          // 离到期时间在3天内
        } else if (stake.end - nowTime > 0) {
          if (stake.autoRenewal === 1) {
            stake.btnState = 2 // 可取消自动续期
          } else {
            stake.btnState = 3 // 等待赎回
          }
        } else {
          if (stake.autoRenewal === 1) {
            stake.btnState = 1
          } else {
            stake.btnState = 4   // 可赎回
          }
        }
      }

      return stake
    }

    const getType = (index) => {
        typeIndex.value = index
      getStakeList()
    }

    const goPage = (url) => {
        router.push({
            path: url
        })
    }

    async function getStakeTypeInfos() {
      stake.staking = 0
      if (wStore.curAccount) {
        const results = await call('stake-v2', 'getStakeInfos', [wStore.curAccount, [1, 2, 3]])
        if (Util.isDefine(results)) {
          for (let i = 0 ; i < results.length ; i++) {
            const staking = Web3.utils.fromWei(results[i].stakeAmount) - 0

            stake.staking += staking
          }
        }

        queryInfo().then(result => {
          if (Util.isDefine(result) && result.data.code === 1) {
            const data = result.data.data.stakeInfo
            stake.compound = data.compoundTopTotal - 0
            stake.interest = data.interest1Remain - 0 + data.interest2Remain + data.interest3Remain
          }
        })
      }
    }

    function cancelAuto(orderSerial) {
      if (wbStore.isLogin()) {
        start()
        cancelAutoRenewal({orderSerial: orderSerial}).then(result => {
          if (Util.isDefine(result) && result.data.code === 1 && result.data.msg === 'success') {
            ElMessage({
              message: i18n.global.t('alert.success'),
              type: 'success'
            })

            for (let i = 0 ; i < stakes.list.length ; i++) {
              if (stakes[i].serial === orderSerial) {
                stakes.list[i].autoRenewal = 0
                stakes.list[i] = countStake(stakes.list[i])
              }
            }
          } else {
            ElMessage({
              message: i18n.global.t('alert.failed'),
              type: 'error'
            })
          }

          end()
        }).catch(function(error) {
          console.error(error)
          end()
        })
      }
    }

    const redeem = (serial) => {
      if (!wbStore.isLogin()) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }

      start()
      redeemOrder({orderSerial: serial}).then(result => {
        if (Util.isDefine(result) && result.data.code === 1) {
          const data = result.data.data

          const orderSerial = data.orderSerial
          const signTime = data.signTime
          const sign = data.sign

          for (let i = 0 ; i < stakes.list.length ; i++) {
            if (serial === stakes.list[i].serial) {
              stakes.list[i].state = -1
              stakes.list[i] = countStake(stakes.list[i])
            }
          }

          send('stake-v2', 'redeem', [orderSerial, signTime, sign])
              .then((receipt) => {
                if (receipt.status) {
                  ElMessage({
                    message: i18n.global.t('alert.success'),
                    type: 'success'
                  })

                } else {
                  ElMessage({
                    message: i18n.global.t('alert.failed'),
                    type: 'error'
                  })
                }
                end()
              }).catch(function(error) {
            console.error(error)
            end()
          })
        }
      })

    }

    onMounted(() => {
      const type = route.query.type
      if (type - 0 > 0 && type - 0 < 4) {
        typeIndex.value = type
      }

      getStakeTypeInfos()
      getStakeList()
    })

    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      stake.staking = 0
      stake.interest = 0
      stake.compound = 0

      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()
          getStakeTypeInfos()
          getStakeList()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })
</script>
<style scoped src="./css/record.css">
</style>