import Api from '@/api/index'

export function queryCompound() {
    return Api.check(
        '/api/member/stake/compound',
        'post'
    )
}

export function queryInfo() {
    return Api.check(
        '/api/member/stake/index',
        'post'
    )
}

export function withdrawInterest(data) {
    return Api.check(
        '/api/member/stake/withdraw/interest',
        'post',
        data
    )
}

export function queryStakeList(data) {
    return Api.check(
        '/api/member/stake/order/list',
        'post',
        data
    )
}

export function withdrawTopBonus() {
    return Api.check(
        '/api/member/stake/withdraw/stake/compound',
        'post'
    )
}

export function cancelAutoRenewal(data) {
    return Api.check(
        '/api/member/stake/cancel/auto/renewal',
        'post',
        data
    )
}

export function redeemOrder(data) {
    return Api.check(
        '/api/member/stake/redeem',
        'post',
        data
    )
}